import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import logo from "../img/Ricewood Logo.svg"

class Navbar extends React.Component {
  render() {
    function toggleMenu() {
      document.querySelector(".navbar-burger").classList.toggle("is-active")
      document.querySelector(".navbar-menu").classList.toggle("is-active")
      ("menu pressed")
    }
    function toggleMoreInfo() {
      document.querySelector('#more_info').classList.toggle('is-active');
    }
    return (
      <nav className="navbar">
        <div className="container" style={{ alignItems: "center" }}>
          <div className="navbar-brand">
            <Link to="/" className="">
              <figure>
                <img
                  src={logo}
                  alt="Ricewood Municipal Utiliy District"
                  className="logo"
                />
              </figure>
            </Link>
            <a
              className="navbar-burger"
              onClick={toggleMenu}
              role="button"
              aria-label="menu"
              aria-expanded="false"
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>
          <div className="navbar-menu">
            <div className="navbar-end">
              <Link className="navbar-item" to="/">Home</Link>
              <Link className="navbar-item" to="/documents">Documents</Link>
              <Link className="navbar-item" to="/bill-payment">Billing</Link>
              <Link className="navbar-item" to="/alerts">Alerts</Link>
              <div id="more_info" className="navbar-item has-dropdown is-hoverable">
                <div className="navbar-link" onClick={toggleMoreInfo}>More Info</div>
                <div className="navbar-dropdown">
                  <Link className="navbar-item" to="/what-is-a-mud">What is a MUD</Link>
                  <Link className="navbar-item" to="/board-and-consultants">
                    Board and Consultants
                  </Link>
                  <Link className="navbar-item" to="/tax-information">Tax Information</Link>
                  <Link className="navbar-item" to="/election-information">Election Information</Link>
                  <Link className="navbar-item" to="/garbage-collection">Garbage Collection</Link>
                  <Link className="navbar-item" to="/ricewood-park">Ricewood Park</Link>
                  <Link className="navbar-item" to="/useful-links">Useful Links</Link>
                  <Link className="navbar-item" to="/first-responders">First Responders</Link>
                  <Link className="navbar-item" to="/calendar">
                    Calendar
                  </Link>
                  <Link className="navbar-item" to="/map">
                    Map
                  </Link>
                </div>
              </div>
              <Link className="navbar-item" to="/contact">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
