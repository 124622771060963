import React from 'react'

const Footer = () => (
  <footer className="section">
    <div className="container">
      <hr/>
      <div className="columns">
        <div className="column is-4 has-text-centered">
          <p>&copy; 2018-{new Date().getFullYear()} Ricewood MUD<br />
          Website provided by <a href="//touchstonedistrictservices.com/">Touchstone</a>
          </p>
        </div>
        <div className="column is-8 has-text-justified">
          <p className="terms">
            The content contained in this website is provided by Ricewood Municipal Utility District for informal informational purposes
            only. This website and the information contained herein should not be
            relied upon or used as current information for the purposes of
            securities disclosure about the district, its financial condition, the
            bonds of the district, or property in the district. Persons should not
            rely upon information in this website when considering whether to buy,
            sell, or hold bonds issued by the district or whether to buy, sell, or
            hold property within the district. The district files official
            continuing disclosure information at least annually in accordance with
            the rules and regulations of the <a target="_blank" rel="noopener noreferrer" href="//www.sec.gov/">Securities and Exchange Commission</a> and
            the <a target="_blank" rel="noopener noreferrer" href="//emma.msrb.org/">Municipal Securities Rulemaking Board</a>. All information contained
            herein speaks only as of the date indicated. The district assumes no
            duty to update any information in this website at any time.
          </p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer;